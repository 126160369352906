import {NgModule} from '@angular/core';
import {ButtonNameDirective} from './toggle-name.directive';
import {PolicyDirective} from './policy.directive';
import {PriceDirective} from './price.directive';

@NgModule({
    // imports: [],
    declarations: [ButtonNameDirective, PolicyDirective, PriceDirective],
    exports: [ButtonNameDirective, PolicyDirective, PriceDirective],
})

export class DirectivesModule {
}
