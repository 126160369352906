import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
    selector: 'ngx-show-modal',
    templateUrl: './show-modal.component.html',
    styleUrls: ['./show-modal.component.scss'],
})
export class ShowModalComponent implements OnInit {

    constructor(
        protected ref: NbDialogRef<ShowModalComponent>,
    ) {
    }

    //#region input proprties
    @Input() title: string;
    @Input() text_delete: string;
    @Input() yes_delete: string;
    @Input() no_delete: string;
    @Input() text_html: string;
    @Input() lstExist: any = [];
    @Input() ok_btn: string;
    @Input() table_title: string;
    @Input() lstProduct: any = [];
    @Input() table_title_menu: string;
    @Input() name;
    @Input() email;

    //#endregion

    //#region properties
    isImg: boolean = false;
    isUser: boolean;
    isTitle: any;
    isShow: boolean;
    titleuser: any;

    //#endregion

    ngOnInit() {
        if (this.lstExist !== undefined) {
            for (let i = 0; i < this.lstExist.length; i++) {
                // this.isImg = this.lstExist[i].img_url ? true : false;
                // this.isUser = this.lstExist[i].email ? true : false;
                this.isImg = !!this.lstExist[i].img_url;
                this.isUser = !!this.lstExist[i].email;
                this.isTitle = this.lstExist[i].label
                    ? this.table_title_menu
                    : this.table_title;
                this.titleuser =
                    this.table_title_menu === undefined
                        ? this.table_title
                        : this.table_title;
            }
        }
        this.showAlert();
    }

    showAlert() {
        if (this.lstExist !== undefined || this.lstProduct !== undefined) {
            // this.isShow = this.lstExist.length || this.lstProduct.length ? true : false;
            this.isShow = !!(this.lstExist.length || this.lstProduct.length);
        } else {
            this.isShow = false;
        }
    }

    close() {
        this.ref.close();
    }

    save() {
        this.ref.close(true);
    }
}
