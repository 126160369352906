<nb-card [status]="this.title ? 'danger' : 'warning'" class="shadow border-0">
    <nb-card-header>
        <div class="row" *ngIf="this.title">
            <div class="col-auto">
                <nb-icon icon="alert-triangle"></nb-icon>
            </div>
            <div class="col">
                <span>{{this.title}}</span>
            </div>
        </div>
        <div class="row" *ngIf="!this.title">
            <div class="col-auto">
                <nb-icon icon="alert-circle"></nb-icon>
            </div>
            <div class="col">
                <span> Suppression impossible</span>
            </div>
        </div>
    </nb-card-header>

    <nb-card-body>
        <div class="container-fluid">
            <div class="row my-3">
                <div class="col">
                    <span>{{this.text_delete}}</span>
                </div>
            </div>

            <div class="row my-3" [ngClass]="{'delete-box-alert': this.isShow == true}" *ngIf="this.lstExist?.length || this.lstProduct?.length">

                <div class="col-12" *ngIf="this.lstExist?.length">
                    <ngx-custom-table>
                        <tr>
                            <th *ngIf="isImg === true"></th>
                            <th *ngIf="isTitle">
                                {{ this.isTitle }}
                            </th>
                            <th *ngIf="titleuser !== '' && table_title_menu !== 'Menus'">
                                {{ this.titleuser }}
                            </th>
                            <th *ngIf="isUser === true">
                                {{ this.email }}
                            </th>
                            <th *ngIf="isUser === true">
                                {{ this.name }}
                            </th>
                        </tr>
                        <tr *ngFor="let item of this.lstExist">
                            <td *ngIf="item?.img_url">
                                <img src="{{ item?.img_url }}" width="100" alt=""/>
                            </td>
                            <td *ngIf="item?.label">
                                {{ item?.label }}
                            </td>
                            <td *ngIf="item?.email">
                                {{ item?.email }}
                            </td>
                            <td *ngIf="item?.firstname || item?.lastname">
                                {{ item?.firstname }} {{ item?.lastname }}
                            </td>
                        </tr>
                    </ngx-custom-table>
                </div>

                <div class="col-12" *ngIf="this.lstProduct?.length">
                    <ngx-custom-table>
                        <tr>
                            <th *ngIf="isImg === true"></th>
                            <th>
                                {{this.table_title}}
                            </th>
                        </tr>
                        <tr *ngFor="let item of this.lstProduct">
                            <td *ngIf="item?.img_url">
                                <img src="{{item?.img_url}}" width="100" alt=""/>
                            </td>
                            <td *ngIf="item?.label">
                                {{item?.label}}
                            </td>
                        </tr>
                    </ngx-custom-table>
                </div>

            </div>
        </div>
    </nb-card-body>

    <nb-card-footer>
        <div class="row justify-content-between text-right">
            <div class="col-auto" *ngIf="this.no_delete">
                <button nbButton hero status="danger" (click)="this.close()">
                    <span>{{this.no_delete}}</span>
                </button>
            </div>
            <div class="col-auto" *ngIf="this.yes_delete">
                <button nbButton hero status="success" (click)="this.save()">
                    <span>{{this.yes_delete}}</span>
                </button>
            </div>
            <div class="col" *ngIf="this.ok_btn">
                <button nbButton hero status="warning" (click)="this.close()">
                    <span>{{this.ok_btn}}</span>
                </button>
            </div>
        </div>
    </nb-card-footer>
</nb-card>
