import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
// import {NbSecurityModule} from '@nebular/security';
import {
    FooterComponent,
    HeaderComponent,
} from './components';
import {
    OneColumnLayoutComponent,
    // ThreeColumnsLayoutComponent,
    // TwoColumnsLayoutComponent,
} from './layouts';
import {DEFAULT_THEME} from './styles/theme.default';
// import {COSMIC_THEME} from './styles/theme.cosmic';
// import {CORPORATE_THEME} from './styles/theme.corporate';
import {DARK_THEME} from './styles/theme.dark';
import {SharedModule} from '../shared/shared.module';
import {NbThemeModule} from '@nebular/theme';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
    exports: [
        CommonModule,
        FooterComponent,
        HeaderComponent,
        OneColumnLayoutComponent,
        // ThreeColumnsLayoutComponent,
        // TwoColumnsLayoutComponent,
    ],
    declarations: [
        FooterComponent,
        HeaderComponent,
        OneColumnLayoutComponent,
        // ThreeColumnsLayoutComponent,
        // TwoColumnsLayoutComponent,
    ],
})

export class ThemeModule {
    static forRoot(): ModuleWithProviders<ThemeModule> {
        return {
            ngModule: ThemeModule,
            providers: [
                ...NbThemeModule.forRoot(
                    {name: 'default'},
                    // [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME],
                    [DEFAULT_THEME, DARK_THEME],
                ).providers,
            ],
        };
    }
}
