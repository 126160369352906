/*
    Account Type Guard
    Created : 05 Nov 2021 by Cameron
*/

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
// import {CommonService} from '../common/common.service';
// import {NbAuthService, NbTokenStorage} from '@nebular/auth';

@Injectable({
    providedIn: 'root',
})

export class AccountTypeGuard implements CanActivate, CanLoad {

    constructor(
        // private router: Router,
        // private commonService: CommonService,
        // public nbTokenStorage: NbTokenStorage,
        // private nbAuthService: NbAuthService,
    ) {
    }

    /* FIXME | REFACTOR USER DATA */
    userData: any = [];

    /* TODO | CAN LOAD */
    canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        // throw new Error('Method not implemented.');
        // console.log('account-type.guard.ts | canLoad');
        return true;
    }

    /* TODO | CAN ACTIVATE */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // console.log('account-type.guard.ts | canActivate');
        return true;
    }

}
