export const environment = {

  production: false,
  api_url: 'https://api.demo.bill-app.fr/',

  /* Base URL */
  web_base_url: 'demo.bill-app.fr',

  /* Endpoints */
  login: 'login/authenticate',

  common_ep: {
    base: 'global/',
    languages: 'language',
    allergens: 'allergens',
    policies: 'policies',
    devices: 'devices',
    fonts: 'fonts',
    pictograms: 'pictograms',
  },

  payment: {
    base: 'payment/',
    transaction: 'transaction/',
    search: 'search',
  },

  admin: {
    base: 'admin/',
    brand: {
      information: '/information',
      asset: '/asset',
    },
    subscription: '/subscription',
    subscriptions: '/subscriptions',
    ordering_slot: '/ordering-slot',
    activate: '/activate',
    deactivate: '/deactivate',
    opening: '/opening',
    groups: '/group',
    users: '/user',
    /* FIXME | Refactor all services using products */
    /* product: "/product" */
    /* products: "/products" */
    products: '/product',
    category: '/category',
    menu: '/menu',
    submenu: '/submenu',
    languageOffered: '/languages',
    devices: '/devices',
    parameter: '/parameter',
    slider: '/slider',
    screensaver: '/screensaver',
    area: '/area',
    areas: '/areas',
    areaLocation: '/area-location',
    card: '/card',
    courses: '/courses',
    menus: '/menus',
    option: '/option',
    options: '/options',
    optionItem: '/option-item',
    optionItems: '/option-items',
    recipe: '/receip',
    productionArea: '/production-area',
    vendorStructure: '/vendor-structure',
    suggestion: '/suggestion',
    advertisement: '/advertising',
    categories: '/categories',
    cards: '/cards',
    pictogram: '/pictogram',
    pictograms: '/pictograms',
    order: '/order',
    taxes: '/taxes',
    event: '/event',
    events: '/events',
    loyalty: '/loyalty',
    customer: '/customer',
    orders: '/orders',
    closingHistory: '/closing-history',
    modules: [
      {id: 1, code: 'b_menu', name: 'B Menu'},
      {id: 2, code: 'b_order', name: 'B Order'},
      {id: 3, code: 'b_webapp', name: 'B WebApp'},
      {id: 4, code: 'b_dedicated', name: 'B App'},
      {id: 5, code: 'b_staff', name: 'B Staff'},
      {id: 6, code: 'b_prod', name: 'B Prod'},
      {id: 7, code: 'b_drive', name: 'B Drive'},
      {id: 8, code: 'b_track', name: 'B Track'},
      {id: 9, code: 'b_pos', name: 'B Pos'},
    ],
    b_parameters: ['b_menu', 'b_order', 'b_webapp'],
  },

  /* Common Variables */
  all: '/all',
  all_product: '/all-product',
  delete_ep: '/delete',
  archive: '/archive',
  deactivate: '/deactivate',
  restore: '/restore',
  duplicate: '/duplicate',
  language_id: 'language_id',
  company_id: 'company_id_guid',
  password: '/password',
  allergens: '/allergens',
  products: '/products',
  product: '/product',
  menu: '/menu',
  image: '/image',
  data: '/data',
  dashboard: '/dashboard',
  detail: '/detail',
  list: '/list',
  channel: '/channel',
  consumption: '/consumption',
  general: '/general',
  icon: '/icon',
  link_way: 'link_way',
  suggested_step: 'suggested_step',
  type: 'type',


  // get_catlist : '/option',

  /* Methods */
  methods: ['GET', 'POST', 'PUT', 'DELETE'],

  /* Type for Category */
  menu_type: 1,
  course_type: 2,

  /* Link Way for Menu and Course */
  course_course: 1,
  course_menu: 2,
  menu_course: 3,
  menu_menu: 4,

  /* Area Location Digits */
  area_location_digits: 10,

  /* Suggestion Step */
  cart_view: 1,
  detail_view: 2,

  /* OUTDATED | Order Status */
  /*orderStatus: {
    defaultOrderStatus: "1",
    cardVerification: "2",
    cardVerified: "3",
    paymentInitiated: "4",
    paymentSuccess: "5",
    paymentFailed: "6",
    orderCancelRefund: "7",
    inProgress: "8",
    ready: "9",
    completed: "10",
  },*/

  /* Order Status */
  orderStatus: {
    // default: 0,
    created: '1',
    upcoming: '2',
    in_progress: '3',
    ready: '4',
    completed: '5',
    cancelled: '6',
  },

  /* Payment Status */
  paymentStatus: {
    card_verification: '1',
    card_verified: '2',
    payment_initiated: '3',
    payment_success: '4',
    payment_failed: '5',
    payment_refund: '6',
  },

  /* OUTDATED | Order Location */
  /*take_away: 0,
  delivery: 1,*/

  /* Consumption Modes */
  consumptionModes: {
    on_the_spot: 1,
    take_away: 2,
    delivery: 3,
  },

  /* Channels */
  channels: {
    totem: '1',
    pos: '2',
    web: '3',
    drive: '4',
    external: '5',
    app: '6',
  },

  /* Item Types */
  menu_item: 1,
  product_item: 2,

  /* Order Timer */
  order_time_interval: 60000,

  /* Assets Sizes */
  smallAssetSize: 'S',
  mediumAssetSize: 'M',
  largeAssetSize: 'L',
  extraLargeAssetSize: 'XL',
};
