/*
    Authorization Service
    Created : 12 Feb 2020 by War
*/

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NbTokenStorage } from '@nebular/auth';

// Some needed modules --------- trying removing one

// Export injectable module
@Injectable()
export class BearerInterceptor implements HttpInterceptor {

    constructor(private tokenStorage: NbTokenStorage) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const userData = this.tokenStorage.get()['token'];

        // console.log(userData)

        if (userData && userData.auth_key) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${userData.auth_key}`,
                },
            });
        }
        return next.handle(req);
    }
}
