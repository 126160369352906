import {NgModule} from '@angular/core';
import {AssetUrlPipe} from './asset-url.pipe';

@NgModule({
  // imports: [],
  declarations: [
    AssetUrlPipe,
  ],
  exports: [
    AssetUrlPipe,
  ],
})

export class PipesModule {
}
