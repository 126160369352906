/*
    Base Api Service
    Created : 12 Feb 2020 by War
*/

// Some needed modules --------- trying removing one
import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable, BehaviorSubject} from 'rxjs';
import {ApiService} from '../api.service';
import {environment} from '../../../../environments/environment';
import {User} from '../../data/common/user.model';
import {Language} from '../../data/common/language.model';
import {Allergen} from '../../data/common/allergen.model';
import {Policy} from '../../data/common/policy.model';
import {NbToastrService, NbDialogService} from '@nebular/theme';
import {ShowModalComponent} from '../../../shared/components/show-modal/show-modal.component';
import {NbTokenStorage} from '@nebular/auth';

// Essential Variables and Annotations
@Injectable({
    providedIn: 'root',
})

// Export Modules
export class CommonService {
    /* FIXME | DEFAULT */
    // private staticContent: any;
    /* USER */
    private userInfo: any = {};
    private currentUser = new BehaviorSubject<User>(null);
    /* LIST */
    private allergenList = new BehaviorSubject<Allergen[]>([]);
    private policyList = new BehaviorSubject<Policy[]>([]);
    /* LANGUAGE */
    private languageList = new BehaviorSubject<Language[]>([]);
    private currentLanguage = new BehaviorSubject<string>('0');
    private currentLanguageCode = '';

    constructor(
        private apiService: ApiService,
        private nbTokenStorage: NbTokenStorage,
        private nbToastrService: NbToastrService,
        private nbDialogService: NbDialogService,
    ) {
    }

    // Display Toast Notification
    showToast(message: any, title: any, status: any, position: any = 'top-right', limit: number = 3, icon: string = '') {
        const userConfig = {
            status: status,
            position: position,
            limit: limit,
            icon: icon,
        };
        // console.log(userConfig);
        this.nbToastrService.show(message, title, userConfig);
    }

    responseHandler(response): void {
        if (response['status'] === 200) {
            this.showToast(response['message'], 'Succès', 'success', 'top-right', 3, 'checkmark');
        } else {
            this.showToast(response['message'], 'Erreur', 'danger', 'top-right', 3, 'close');
        }
    }

    // Set Data of Logged In User
    setUserData(user: User) {
        this.currentUser.next(user);
    }

    // Get Logged In User Data
    getUserData(): User {
        return this.currentUser.value;
    }

    // User Observable
    onUserChange(): Observable<User> {
        return this.currentUser;
    }

    // Get all Languages
    fetchAllLanguage(): Observable<Response> {
        return new Observable<Response>((obs) => {
            this.apiService
                .get(`${environment.common_ep.base}${environment.common_ep.languages}`)
                .subscribe((res) => {
                    obs.next(res);
                });
        });
    }

    // Set Language List
    setLanguageList(languages: Language[], language_id: string, language_code: string) {
        this.currentLanguageCode = language_code;
        this.languageList.next(languages);
        this.currentLanguage.next(language_id);
    }

    // Get Language List
    getLanguageList(): Language[] {
        return this.languageList.value;
    }

    // Language List Observable
    onLanguageListChange(): Observable<Language[]> {
        return this.languageList;
    }

    // Set Selected Language
    setLanguage(language_id: string) {
        this.languageList.value.forEach((element) => {
            if (element.id === language_id) {
                this.currentLanguageCode = element.code_iso;
            }
        });
        this.currentLanguage.next(language_id);
    }

    // Get Selected Language
    getSelectedLanguage(): string {
        return this.currentLanguage.value;
    }

    // Get Selected Language Code
    getSelectedLanguageCode(): string {
        return this.currentLanguageCode;
    }

    // Language Change Observable
    onLanguageChange(): Observable<string> {
        return this.currentLanguage.asObservable();
    }

    // Get all Allergens
    fetchAllAllergens(): Observable<Response> {
        const params: HttpParams = new HttpParams().set(
            environment.language_id,
            this.currentLanguage.value,
        );
        return new Observable<Response>((obs) => {
            this.apiService
                .get(`${environment.common_ep.base}${environment.common_ep.allergens}`, params)
                .subscribe((res) => {
                    obs.next(res);
                });
        });
    }

    // Set Allergen List
    setAllergenList(allergens: Allergen[]) {
        // console.log(allergens)
        this.allergenList.next(allergens);
    }

    // Get Allergen List
    getAllergenList(): Allergen[] {
        return this.allergenList.value;
    }

    // Allergen List Observable
    onAllergenListChange(): Observable<Allergen[]> {
        return this.allergenList;
    }

    // Get all Policies
    fetchAllPolicies(): Observable<Response> {
        return new Observable<Response>((obs) => {
            /* FIXME | language_id */
            /*const params: HttpParams = new HttpParams().set(
                environment.language_id,
                this.currentLanguage.value,

                .get(`${environment.common_ep.base}${environment.common_ep.policies}`, params)
            );*/
            this.apiService
                .get(`${environment.common_ep.base}${environment.common_ep.policies}`)
                .subscribe((res) => {
                    // console.log(res);
                    obs.next(res);
                });
        });
    }

    // Set Policy List
    setPolicyList(policies: Policy[]) {
        // console.log(policies)
        this.policyList.next(policies);
    }

    // Get Policy List
    getPolicyList(): Policy[] {
        return this.policyList.value;
    }

    // Policy List Observable
    onPolicyListChange(): Observable<Policy[]> {
        return this.policyList;
    }

    // Get all Devices
    fetchAllDevices(): Observable<Response> {
        return new Observable<Response>((obs) => {
            this.apiService
                .get(`${environment.common_ep.base}${environment.common_ep.devices}`)
                .subscribe((res) => {
                    obs.next(res);
                });
        });
    }

    // Get all Fonts
    fetchAllFonts(): Observable<Response> {
        return new Observable<Response>((obs) => {
            this.apiService
                .get(`${environment.common_ep.base}${environment.common_ep.fonts}`)
                .subscribe((res) => {
                    obs.next(res);
                });
        });
    }

    // Get all Pictograms
    fetchAllPictograms(): Observable<Response> {
        const params: HttpParams = new HttpParams().set(
            environment.language_id,
            this.currentLanguage.value,
        );
        return new Observable<Response>((obs) => {
            this.apiService
                .get(`${environment.common_ep.base}${environment.common_ep.pictograms}`, params)
                .subscribe((res) => {
                    obs.next(res);
                    obs.complete();
                });
        });
    }

    // Price Float Check
    floatCheck(price) {
        return price % 1 === 0 ? price.toFixed(0) : price.toFixed(2);
    }

    deleteCommonAlert(alert, mode?) {
        return this.nbDialogService.open(ShowModalComponent, {
            hasScroll: true,
            context: {
                title:
                    mode === 1
                        ? alert.title
                        : mode === 2
                            ? alert.title
                            : mode === 3
                                ? alert.title
                                : '',
                text_delete:
                    mode === 1
                        ? alert.text_delete
                        : mode === 2
                            ? alert.text
                            : mode === 3
                                ? alert.text_refund
                                : '',
                yes_delete:
                    mode === 1
                        ? alert.yes_delete
                        : mode === 2
                            ? alert.yes
                            : mode === 3
                                ? alert.yes_refund
                                : '',
                no_delete:
                    mode === 1
                        ? alert.no_delete
                        : mode === 2
                            ? alert.no
                            : mode === 3
                                ? alert.no_refund
                                : '',
            },
        });
    }

    deleteAlert(staticContent, lst) {
        return this.nbDialogService.open(ShowModalComponent, {
            context: {
                text_delete: staticContent.ismsgDelete ? staticContent.ismsgDelete : '',
                lstExist: lst ? lst : [],
                ok_btn: staticContent.alert.ok ? staticContent.alert.ok : '',
                table_title: staticContent.table_title ? staticContent.table_title : '',
                email: staticContent.email ? staticContent.email : '',
                name: staticContent.name ? staticContent.name : '',
            },
        });
    }

    deleteListBind(menu_data, product_data, staticContent) {
        return this.nbDialogService.open(ShowModalComponent, {
            context: {
                title: !(menu_data.length || product_data.length) ? staticContent.alert.title : '',
                text_delete: !(menu_data.length || product_data.length) ? staticContent.alert.text_delete : staticContent.ismsgDelete,
                yes_delete: staticContent.alert.yes_delete,
                no_delete: staticContent.alert.no_delete,
                lstExist: menu_data ? menu_data : [],
                lstProduct: product_data ? product_data : [],
                table_title: staticContent.products,
                table_title_menu: staticContent.menus,
            },
        });
    }

    /* FIXME OLD | IS GRANTED */
    /*isGranted(policy: string) {
        console.log(policy);
        this.userInfo = this.nbTokenStorage.get()['token'];
        console.log(this.userInfo);
        // console.log(this.userInfo.policies);
        let getPermission;
        if (this.userInfo) {
            getPermission = this.userInfo.policies.find((userPolicy: string) => {
                // console.log(userPolicy);
                return userPolicy === policy;
            });
        }
        console.log(getPermission !== undefined);
        return getPermission !== undefined;
    }*/

    /* TODO | IS GRANTED */
    isGranted(policy: string) {
        // console.log('policy', policy);
        /* FIXME | REFACTOR USER DATA */
        this.userInfo = this.nbTokenStorage.get()['token'];
        // console.log(this.userInfo);
        if (this.userInfo) {
            // console.log('return', this.userInfo.policies.some((userPolicy: string) => userPolicy === policy));
            return this.userInfo.policies.some((userPolicy: string) => userPolicy === policy);
        } else {
            return false;
        }
    }

}
