/*
    Base Api Service
    Created : 12 Feb 2020 by War
*/

// Some needed modules --------- trying removing one
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/internal/operators/catchError';
import {environment} from '../../../environments/environment';

// Essential Variables and Annotations
@Injectable({
  providedIn: 'root',
})

// Export Modules
export class ApiService {

  constructor(private http: HttpClient) {
  }

  // Error Handling
  formatErrors(error: any) {
    // console.log(error);
    if (error.error.message) {
      return [{status: error.status, message: error.error.message}];
    } else {
      return [{status: error.status, message: 'I think you have been disconnected!'}];
    }
  }

  // GET Request Handler
  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    // console.log(path)
    return this.http
      .get(`${environment.api_url}${path}`, {params})
      .pipe(catchError(this.formatErrors));
  }

  // POST Request Handler
  post(path: string, body: Object = {}, headers?: HttpHeaders): Observable<any> {
    return this.http
      .post(`${environment.api_url}${path}`, body, {headers})
      .pipe(catchError(this.formatErrors));
  }

  // PUT Request Handler
  put(path: string, body: Object = {}, headers?: HttpHeaders): Observable<any> {
    return this.http
      .put(`${environment.api_url}${path}`, body, {headers})
      .pipe(catchError(this.formatErrors));
  }

  // DELETE Request Handler
  delete(path, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http
      .delete(`${environment.api_url}${path}`, {params})
      .pipe(catchError(this.formatErrors));
  }

}
