import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NbAuthModule, NbPasswordAuthStrategy, NbAuthSimpleToken} from '@nebular/auth';
import {NbSecurityModule, NbRoleProvider} from '@nebular/security';
import {of} from 'rxjs';

import {throwIfAlreadyLoaded} from './module-import-guard';
import {LayoutService, StateService} from './utils';
import {environment} from '../../environments/environment';

export class NbSimpleRoleProvider extends NbRoleProvider {
    getRole() {
        // here you could provide any role based on any auth flow
        return of('guest');
    }
}

export const NB_CORE_PROVIDERS = [
    ...NbAuthModule.forRoot({

        // Authorization and Login Strategies

        strategies: [
            // NbDummyAuthStrategy.setup({
            //   name: 'email',
            //   delay: 3000,
            // }),
            NbPasswordAuthStrategy.setup({
                name: 'email',
                baseEndpoint: environment.api_url,
                token: {
                    class: NbAuthSimpleToken,
                    key: 'data',
                },
                login: {
                    endpoint: environment.login,
                    method: environment.methods[1],
                    redirect: {
                        success: '/dashboard',
                    },
                    // defaultErrors: ['Email/Password combination is not correct, please try again.'],
                    defaultErrors: ['Veuillez vérifier votre email/mot de passe.'],
                    // defaultMessages: ['You have been successfully logged in.'],
                    defaultMessages: ['Vous allez être redirigé...'],
                },
                logout: {
                    method: null,
                    endpoint: null,
                    alwaysFail: false,
                    redirect: {
                        success: '/auth/',
                        failure: '/auth/',
                    },
                },
            }),
        ],
        forms: {
            login: {
                // socialLinks: socialLinks,
                redirectDelay: 0,
                showMessages: {
                    success: true,
                    error: true,
                },
            },
            register: {
                // socialLinks: socialLinks,
            },
        },
    }).providers,

    NbSecurityModule.forRoot({
        accessControl: {
            guest: {
                view: '*',
            },
            user: {
                parent: 'guest',
                create: '*',
                edit: '*',
                remove: '*',
            },
        },
    }).providers,

    {
        provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
    },
    LayoutService,
    StateService,
];

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        NbAuthModule,
    ],
    declarations: [],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                ...NB_CORE_PROVIDERS,
            ],
        };
    }
}
