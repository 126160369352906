/*
    Error Service
    Created : 12 Feb 2020 by War
*/

import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NbTokenStorage} from '@nebular/auth';

// Some needed modules --------- trying removing one

// Export injectable module
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private tokenStorage: NbTokenStorage,
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const userData = this.tokenStorage.get()['token'];

        return next
            .handle(req)
            .pipe(catchError((err) => {
                // console.log(err)

                /* FIXME | LOGOUT REDIRECTION DEPENDING ERRORS */
                if (userData && (err.status === 302 || (err.status === 401 && err.statusText === 'Unauthorized' && !err.error.message))) {
                    this.router
                        .navigate(['/auth/logout'])
                        .then((navigation) => {
                            // console.log('navigation', navigation);
                            if (navigation) {
                                localStorage.clear();
                                sessionStorage.clear();
                            }
                        });
                }
                return throwError(err);
            }));
    }
}
