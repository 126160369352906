import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from './@core/guards/authguard.service';
// import {PolicyGuard} from './@core/utils/policy/policy.guard';

const routes: Routes = [
    {
        path: 'pages',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        // canActivate: [AuthGuard, PolicyGuard],
        // canActivate: [AuthGuard],
        canLoad: [AuthGuard],
        canActivateChild: [AuthGuard],
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.NgxAuthModule),
        // canActivate : [AuthGuard],
    },
    {path: '', redirectTo: 'auth', pathMatch: 'full'},
    {path: '**', redirectTo: 'pages'},
];

const config: ExtraOptions = {
    useHash: false,
    relativeLinkResolution: 'corrected',
    enableTracing: false, // <-- debugging purposes only
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
