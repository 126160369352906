/*
    Authorization Guard Service
    Created : 12 Feb 2020 by War
*/

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {NbAuthService} from '@nebular/auth';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private authService: NbAuthService,
        private router: Router,
    ) {
    }

    /* CAN ACTIVATE CHILD */
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        // throw new Error('Method not implemented.');
        // console.log('authguard.service.ts | canActivateChild', this.authService.isAuthenticated().subscribe((authenticated) => console.log(authenticated)));
        // console.log('authguard.service.ts | canActivateChild', childRoute);
        return this.authService.isAuthenticated()
            .pipe(
                tap(authenticated => {
                    if (!authenticated) {
                        this.router.navigate(['auth']);
                    }
                }),
            );
    }

    /* CAN ACTIVATE */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // console.log('authguard.service.ts | canActivate', this.authService.isAuthenticated().subscribe((authenticated) => console.log(authenticated)));
        // console.log('authguard.service.ts | canActivate', route);
        return this.authService.isAuthenticated()
            .pipe(
                tap(authenticated => {
                    if (!authenticated) {
                        this.router.navigate(['auth']);
                    }
                }),
            );
    }

    /* CAN LOAD */
    canLoad(route, segments): Observable<boolean> | Promise<boolean> | boolean {
        // console.log('authguard.service.ts | canLoad', this.authService.isAuthenticated().subscribe((authenticated) => console.log(authenticated)));
        // console.log('authguard.service.ts | canLoad', route);
        return this.authService.isAuthenticated()
            .pipe(
                tap(authenticated => {
                    if (!authenticated) {
                        this.router.navigate(['auth']);
                    }
                }),
            );
    }
}
