// Created by harsh
// 18/02/2021

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
