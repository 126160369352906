import {Injectable, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

/* NG PICK DATETIME */
import {OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';

/* CHARTS */
// import {NgxChartsModule} from '@swimlane/ngx-charts';
import {ChartsModule} from 'ng2-charts';

// import {ChartModule} from 'angular2-chartjs';

/* FONTAWESOME ICONS */
// import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
// import {library} from '@fortawesome/fontawesome-svg-core';
// import {faPowerOff, faHouseBlank} from '@fortawesome/pro-regular-svg-icons';

/* COMPONENTS */
import {CustomGridItemComponent} from './components/custom-grid-item/custom-grid-item.component';
import {CustomItemComponent} from './components/custom-item/custom-item.component';
import {CustomItemsGridComponent} from './components/custom-items-grid/custom-items-grid.component';
import {CustomTableComponent} from './components/custom-table/custom-table.component';
import {ScrollToTopButtonComponent} from './components/scroll-to-top-button/scroll-to-top-button.component';
import {RefundDialogComponent} from './components/refund-dialog/refund-dialog.component';
import {ShowModalComponent} from './components/show-modal/show-modal.component';
/* FILTERS */
import {DisableFiltersButtonComponent} from './components/filters/disable-filters-button/disable-filters-button.component';
import {OrderByButtonComponent} from './components/filters/order-by-button/order-by-button.component';
import {SearchInputComponent} from './components/filters/search-input/search-input.component';
import {ThreeStatesToggleComponent} from './components/filters/three-states-toggle/three-states-toggle.component';
import {TwoStatesToggleComponent} from './components/filters/two-states-toggle/two-states-toggle.component';

/* DIRECTIVES */
import {DirectivesModule} from './directives/directives.module';

/* PIPES */
import {PipesModule} from './pipes/pipes.module';

/* NEBULAR */
import {NebularModule} from './nebular.module';

/* SORTABLE */
import {SortablejsModule} from 'ngx-sortablejs';

/* FIXME | NGB MODAL => TO REMOVE */
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class PickerTranslation extends OwlDateTimeIntl {
  rangeFromLabel = 'Du ';
  rangeToLabel = 'Au ';
  setBtnLabel = 'Valider';
  cancelBtnLabel = 'Annuler';
}

@NgModule({
  declarations: [
    CustomGridItemComponent,
    CustomItemComponent,
    CustomItemsGridComponent,
    CustomTableComponent,
    RefundDialogComponent,
    ScrollToTopButtonComponent,
    ShowModalComponent,

    DisableFiltersButtonComponent,
    OrderByButtonComponent,
    SearchInputComponent,
    ThreeStatesToggleComponent,
    TwoStatesToggleComponent,
  ],
  exports: [
    DirectivesModule,
    PipesModule,
    FormsModule,
    NebularModule,
    ChartsModule,
    NgbModalModule,
    // NgxChartsModule,
    SortablejsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    // ChartModule,
    DirectivesModule,
    NebularModule,
    NgbModalModule,
    // NgxChartsModule,
    SortablejsModule,

    CustomGridItemComponent,
    CustomItemComponent,
    CustomItemsGridComponent,
    CustomTableComponent,
    RefundDialogComponent,
    ScrollToTopButtonComponent,
    ShowModalComponent,

    DisableFiltersButtonComponent,
    OrderByButtonComponent,
    SearchInputComponent,
    ThreeStatesToggleComponent,
    TwoStatesToggleComponent,
    /* FONTAWESOME ICONS */
    // FontAwesomeModule,
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    PipesModule,
    FormsModule,
    NebularModule,
    ChartsModule,
    NgbModalModule,
    // NgxChartsModule,
    SortablejsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    /* FONTAWESOME ICONS */
    // FontAwesomeModule,
  ],
  providers: [
    {
      provide: OwlDateTimeIntl,
      useClass: PickerTranslation,
    },
   // ChartModule,
    CommonModule,
    DirectivesModule,
    FormsModule,
    NebularModule,
    NgbModalModule,
    // NgxChartsModule,
    SortablejsModule,
  ],
  // entryComponents: [ShowModalComponent]
})

// Export Modules
export class SharedModule {
  /*constructor() {
    /!* FONTAWESOME ICONS *!/
    library.add(faPowerOff, faHouseBlank);
  }*/
}
