/*
    Policy Guard
    Created : 05 Nov 2021 by Cameron
*/

import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {CommonService} from '../utils/common/common.service';
// import {NbAuthService, NbTokenStorage} from '@nebular/auth';

@Injectable({
    providedIn: 'root',
})

export class PolicyGuard implements CanActivate {

    constructor(
        private router: Router,
        private commonService: CommonService,
        // public nbTokenStorage: NbTokenStorage,
        // private nbAuthService: NbAuthService,
    ) {
    }

    /* FIXME | REFACTOR USER DATA */
    userData: any = [];

    /* FIXME | UNUSED CAN ACTIVATE INTERNAL */
    /*canActivateInternal(data: any, state: RouterStateSnapshot): Observable<boolean> {
        // console.log('canActivateInternal', data);

        if (!data || !data['permission']) {
            return of(true);
        }
        if (this.commonService.isGranted(data['permission'])) {
            return of(true);
        } else {
            this.router
                .navigate(['/pages/dashboard'])
                .then((navigation) => {
                    if (navigation) {
                        return of(false);
                    }
                });
        }
        //  this.router.navigate([this.selectRoute()]);
    }*/

    /* FIXME | UNUSED SELECT ROUTE */
    /*selectRoute(): string {
        // console.log('selectRoute');

        if (!this.userData) {
            this.router.navigate(['auth']);
        }

        if (this.commonService.isGranted('get-information')) {
            return '/information';
        }

        if (this.commonService.isGranted('get-subscription')) {
            return '/modules';
        }

        if (this.commonService.isGranted('get-area')) {
            return '/area-management';
        }

        if (this.commonService.isGranted('get-production-area')) {
            return '/production-area';
        }
        if (this.commonService.isGranted('get-vendor-structure')) {
            return '/vendor-structure';
        }

        return '/pages/dashboard';
    }*/

    /*canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        console.log('canActivate');

        return this.canActivateInternal(next.data, state);
    }*/

    /* TODO | CAN ACTIVATE */
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        let is_granted: boolean = false;
        const components: any[] = [];

        Object.entries(next.data).forEach(([component, params]) => {
            // console.log('component', component);
            // console.log('params', params);

            const tempComponent = {
                component: component,
                is_granted: null,
            };

            if (params.fullMatch) {
                tempComponent.is_granted = params.policies.every((policy) => {
                    // console.log(this.commonService.isGranted(policy));
                    return this.commonService.isGranted(policy);
                });
                // console.log('every | tempComponent', tempComponent.is_granted);
                components.push(tempComponent);
            } else {
                tempComponent.is_granted = params.policies.some((policy) => {
                    // console.log(this.commonService.isGranted(policy));
                    return this.commonService.isGranted(policy);
                });
                // console.log('some | tempComponent', tempComponent.is_granted);
                components.push(tempComponent);
            }
        });
        // console.log(components);
        // console.log('before is_granted', is_granted);
        is_granted = components.every((component) => {
            return component.is_granted;
        });
        // console.log('after is_granted', is_granted);
        return is_granted;
    }

    /*canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // console.log('canActivate | next', next);
        // console.log('canActivate | state', state);

        // return this.canActivateInternal(next.data, state);

        let is_granted: boolean = false;

        if (next.data.policies) {
            next.data.policies.forEach((policy) => {
                console.log(policy, this.commonService.isGranted(policy));
                if (this.commonService.isGranted(policy)) {
                    is_granted = true;
                }
            });
            return is_granted;
        } else {
            this.router
                .navigate(['/pages/dashboard'])
                .then((navigation) => {
                    console.log(navigation);
                    return is_granted;
                });

        }
    }*/

    /* FIXME | UNUSED CAN LOAD */
    /*canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
        console.log('canLoad');

        return this.canActivateInternal(route.data, null);
    }*/

}
